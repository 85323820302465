@tailwind base;
@tailwind components;
@tailwind utilities;

@import "basic";


:root{
	// --cmsLinkColor: theme('colors.brown.DEFAULT');
}
.top-logo{
	@screen lg{
		svg{
			max-width: 130px;
    		height: auto;
		}
	}
	&.is-blue{
		.cls-1{
			fill: #3e3a39;
		}
		.cls-2{
			fill: #13294b;
		}
	}
	.cls-1{
		fill: #fff;
		transition: all .5s;
	}
	.cls-2{
		fill: #fff;
		transition: all .5s;
	}
}
.gogo{
	&:hover{
		.svg-arrow{
			animation: viewmore-arrow .5s;
		}
	}
	.svg-arrow{
		@keyframes viewmore-arrow{
			0% {
				transform: translateX(0%);
			}
			45.99% {
				transform: translateX(150%);
				opacity: 0
			}
			46% {
				transform: translateX(-150%);
				opacity: 0
			}
			to {
				transform: translateX(0%);
				opacity: 1
			}
		}
	}
}
.logo-head{
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 1.5s;
	pointer-events: none;
	@screen lg{
		transition: all 0.85s;
	    max-width: 130px;
	}
	&.is-small{
	    left: -24px;
    	top: -30px;
		transform: translate(0px, 0px) scale(0.5);
		@screen lg{
			left: 14px;
    		top: 14px;
    		transform: translate(0px, 0px);
		    max-width: 70px;
		}
	}
	&.is-hide{
		opacity: 0;
	}
}